import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import uuid from "react-uuid";
import Collapse from "react-bootstrap/Collapse";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import ResourceOrActivityModal from "./Modals/resourceOrActivityModal";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import SettingsSvg from "../../Components/Svg/settingsSvg";
import ImageSvg from "../../Components/Svg/imageSvg";
import QuizSvg from "../../Components/Svg/quizSvg";
import VideoSvg from "../../Components/Svg/videoSvg";
import AudioSvg from "../../Components/Svg/audioSvg";
import FileSvg from "../../Components/Svg/fileSvg";
import CloseSvg from "../../Components/Svg/closeSvg";
import PosterImage from "./../../assets/images/video-poster.png";
import Parser from "html-react-parser";
import ShowMoreText from "react-show-more-text";
import {
	setPureLayoutBackUrl,
	setCuriculumBreadCrumb,
} from "../../Store/Actions/main";
import { setCurrentCourse } from "../../Store/Actions/course";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
	AUDIO_KEY,
	ERROR_KEY,
	FILE_KEY,
	GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID,
	GAMIFY_QUIZ_ENTITY_TYPE_ID,
	GAMIFY_SECTION_ENTITY_TYPE_ID,
	GAMIFY_TOPIC_ENTITY_TYPE_ID,
	IMAGE_KEY,
	QUIZ_KEY,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
	VIDEO_KEY,
} from "../../Constants/mainKeys";
import {
	QUIZ_FORM_KEY,
	MEDIA_FORM_KEY,
	VIEW_KEY,
	QUIZ_VIEW_KEY,
	QUIZ_ATTEMPT_KEY,
	COURSE_DASHBOARD_KEY,
	CURICULUMN_KEY,
	PURE_KEY,
	COURSES_KEY,
	ORGANIZATION_KEY,
} from "../../Constants/urlKeys";
import GameSvg from "./../../Components/Svg/gameSvg";
import GamifyApiService from "../../Services/gamifyApiService";
import GamificationItemsComponent from "../../OrganizationPages/Gamification/Components/Modals/GamificationItemsComponent";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class Topics extends Component {
	state = {
		sectionId: +this.props.sectionId,
		courseId: +this.props.match.params.courseId,
		currentTopicId:
			this.props.curiculumBreadCrumb &&
			this.props.curiculumBreadCrumb.currentTopicId,
		topics: this.props.topics,
		updateTopic: null,
		deleteTopicId: null,
		spinnerId: null,
		topicIdForResource: null,
		showResourceOrActivity: false,
		isShowTopicForm: false,
		quizzes: [],
		topicQuizzes: [],
		quizzesAssignments: [],
		topicAttachedFiles: [],
		translationService: null,
		dragTopicId: null,
		dropTopicId: null,
		zoomImagePath: null,
		zoomAudioPath: null,
		zoomVideoPath: null,
		ongoingVideoId: null,
		isLoading: false,
		gamifyData: null,
		selectedEntityData: null,
		gamifyEntityTypeId: null,
	};

	componentDidMount() {
		this.setTranslations();
		const { currentTopicId } = this.state;
		if (currentTopicId) {
			this.getFileLibraryByTopicId(currentTopicId);
			this.getQuizzesByTopicId(currentTopicId);
			this.getQuizzesAssignment(currentTopicId);
		}
		// if (this.props.topics && this.props.topics.length) {
		//   this.openTopicData(this.props.topics[0].id, true);
		// }
		window.addEventListener("keydown", this.zoomOut);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.zoomOut);
	}

	componentDidUpdate() {
		this.setTranslations();
		if (
			this.props.topics &&
			this.props.topics.length &&
			JSON.stringify(this.props.topics) !== JSON.stringify(this.state.topics)
		) {
			this.setState({
				topics: this.props.topics,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		if (
			this.props.sectionId !== nextProps.sectionId ||
			JSON.stringify(this.props.topics) !== JSON.stringify(nextProps.topics)
		) {
			this.setState({
				sectionId: nextProps.sectionId,
				topics: nextProps.topics,
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getFileLibraryByTopicId = topicId => {
		if (!this.props.isBelongToUser) {
			return;
		}
		ApiService.getFileLibraryByTopicId(topicId, false)
			.then(response => {
				this.setState({ topicAttachedFiles: response.data });
			})
			.catch(error => this.getFail(error));
	};

	getQuizzesByTopicId = topicId => {
		if (!this.props.isBelongToUser) {
			return;
		}
		ApiService.getQuizzesByTopicId(topicId)
			.then(response => {
				this.setState({ topicQuizzes: response.data });
			})
			.catch(error => this.getFail(error));
	};
	getQuizzesAssignment = topicId => {
		if (!topicId) {
			return;
		}
		ApiService.getQuizzesAssignment(topicId)
			.then(response => {
				this.setState({ quizzesAssignments: response.data });
			})
			.catch(error => this.getFail(error));
	};

	openTopicData = (id, firstTime) => {
		if (id === this.state.currentTopicId) {
			if (firstTime) {
				return;
			}
			this.setState({ currentTopicId: null }, () =>
				this.setCuriculumBreadCrumb(),
			);
		} else {
			this.setState({ currentTopicId: id }, () =>
				this.setCuriculumBreadCrumb(),
			);
			this.getFileLibraryByTopicId(id);
			this.getQuizzesByTopicId(id);
			this.getQuizzesAssignment(id);
		}
	};

	setCuriculumBreadCrumb = () => {
		const curiculumBreadCrumb = { ...this.props.curiculumBreadCrumb };
		curiculumBreadCrumb.currentTopicId = this.state.currentTopicId;
		this.props.setCuriculumBreadCrumb(curiculumBreadCrumb);
	};

	toggleResourceOrActivity = (show, topic = null) => {
		const { user } = this.props;
		if (!show) {
			const { history, language } = this.props;
			history.push(
				user.isOrganizationUser
					? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${this.state.courseId}/curriculum`
					: `/${language}/${COURSE_DASHBOARD_KEY}/${this.state.courseId}/${CURICULUMN_KEY}`,
			);
			this.setState({ topicIdForResource: null });
		}
		this.setState({
			showResourceOrActivity: show,
			topicIdForResource: topic ? topic.id : null,
		});
	};

	addRecourseOrActivity = id => {
		const { history, language } = this.props;
		const { courseId } = this.state;
		const hash = history.location.hash.substring(1);
		if (hash === "assignment") {
			const path = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${id}/assignment-form`;
			history.push(path);
			return;
		} else if (hash) {
			const path =
				hash === QUIZ_KEY
					? `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${id}/${QUIZ_FORM_KEY}`
					: `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${id}/${MEDIA_FORM_KEY}?type=${hash}`;
			history.push(path);
		}
	};

	removeAttachedFile = (topicId, attachedFile) => {
		const { translationService } = this.state;
		const { user } = this.props;
		AlertService.alertConfirm(
			`${translationService.translate("TR_REMOVE_CONFIRM_MESSAGE")} ${
				attachedFile.nameAsResource
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				const form = {
					fileLibraryId: attachedFile.id,
					courseTopicId: topicId,
					create: false,
				};
				(user.isOrganizationUser
					? ApiService.attachFileToTopicOrg(form)
					: ApiService.attachFileToTopic(form)
				)
					.then(() => {
						this.props.getCourseById();
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						const attachedFiles = this.state.topicAttachedFiles;
						const newAttachedFiles = attachedFiles.filter(
							data => data.id !== attachedFile.id,
						);
						this.setState({ topicAttachedFiles: newAttachedFiles });
					})
					.catch(e => this.submitFail(e, null));
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			);
	};

	openTopicForm = (topic = null) => {
		this.setState({ isShowTopicForm: true, updateTopic: topic });
	};

	topicSubmitted = () => {
		this.setState({ isShowTopicForm: false, updateTopic: null });
		this.props.getCourseById();
	};

	deleteTopic = topic => {
		const { translationService } = this.state;
		const { user } = this.props;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				topic.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				(user.isOrganizationUser
					? ApiService.topicDeleteByOrgUser(topic.id)
					: ApiService.topicDelete(topic.id)
				)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						this.props.getCourseById();
						const topics = this.state.topics.filter(
							data => data.id !== topic.id,
						);
						this.setState({ topics });
					})
					.catch(error => this.deleteFail(null, error));
			})
			.catch(error => this.deleteFail(null, error));
	};

	deleteQuiz = quiz => {
		const { translationService } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				quiz.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				ApiService.quizDelete(quiz.id)
					.then(() => {
						const topicQuizzes = [...this.state.topicQuizzes];
						const newTopicQuizzes = topicQuizzes.filter(
							data => data.id !== quiz.id,
						);
						this.setState({ topicQuizzes: newTopicQuizzes });
					})
					.catch(error => this.getFail(error));
			})
			.catch(error => this.deleteFail(error));
	};

	drop(dropTopicId) {
		const { dragTopicId } = this.state;
		const { user } = this.props;
		const spinnerId = uuid();
		if (
			dropTopicId &&
			dragTopicId &&
			dropTopicId !== dragTopicId &&
			this.props.editMode
		) {
			this.props.addPageSpinner(spinnerId);
			(user.isOrganizationUser
				? ApiService.setTopicOrderByOrgUser(dropTopicId, dragTopicId)
				: ApiService.setTopicOrder(dropTopicId, dragTopicId)
			)
				.then(response => {
					const currentCourse = { ...response.data };
					this.props.setCurrentCourse(currentCourse);
					this.props.changeTopicsData(currentCourse);
					this.props.removePageSpinner(spinnerId);
				})
				.catch(error => this.getFail(error, spinnerId));
		}
	}

	drag(topicId) {
		topicId && this.setState({ dragTopicId: topicId });
	}

	allowDrop(event) {
		event.preventDefault();
	}

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		this.setState({ zoomVideoPath: videoPath });
	};

	zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		this.setState({ zoomAudioPath: audioPath });
	};

	zoomOut = event => {
		if (event.key === "Escape") {
			this.cancel();
		}
	};

	getGamifyDataByEntity = (
		entityData,
		entityType,
		parentTypeId,
		parentId = null,
	) => {
		const { isLoading, sectionId } = this.state;
		if (!entityData || !entityType || isLoading) {
			return false;
		}
		this.setState({ isLoading: true, gamifyEntityTypeId: entityType });
		const data = {
			entityId: entityData.id,
			entityTypeId: entityType,
			parentId: parentId
				? +parentId
				: sectionId
				? +sectionId
				: entityData.entityId
				? +entityData.entityId
				: null,
			parentTypeId,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					this.setState({
						gamifyData: response.data,
						selectedEntityData: entityData,
					});
				}
				this.setState({ isLoading: false });
			})
			.catch(error => this.getFail(error));
	};

	// getGamifyDataByEntity = (entityData, entityType, parentTypeId) => {
	// 	const { isLoading, sectionId } = this.state;
	// 	if (!entityData || !entityType || isLoading) {
	// 		return false;
	// 	}
	// 	this.setState({ isLoading: true, gamifyEntityTypeId: entityType });
	// 	const data = {
	// 		entityId: entityData.id.toString(),
	// 		entityTypeId: entityType,
	// 		parentId: sectionId
	// 			? +sectionId
	// 			: entityData.entityId
	// 			? +entityData.entityId
	// 			: null,
	// 		parentTypeId,
	// 	};
	// 	GamifyApiService.getGamifyDataByEntity(data)
	// 		.then(response => {
	// 			if (response && response.data) {
	// 				this.setState({
	// 					gamifyData: response.data,
	// 					selectedEntityData: entityData,
	// 				});
	// 			}
	// 			this.setState({ isLoading: false });
	// 		})
	// 		.catch(error => this.getFail(error));
	// };
	closeGamifyMoadl = () => {
		this.setState({
			selectedEntityData: null,
			gamifyData: null,
			isLoading: false,
			gamifyEntityTypeId: null,
		});
	};

	cancel = () => {
		this.setState({
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
		});
	};

	submitFail = (message, spinnerId) => {
		message &&
			AlertService.alert(
				AlertService.checkMessageType(message.respcode) || ERROR_KEY,
				message,
			);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
	};

	deleteFail = (spinnerId, message) => {
		this.props.removeButtonSpinner(spinnerId);
		this.setState({ deleteSectionId: null });
		message &&
			AlertService.alert(
				AlertService.checkMessageType(message.respcode) || ERROR_KEY,
				message,
			);
	};
	deleteAssigment = assignment => {
		const { translationService, quizzesAssignments } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				assignment.title
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				ApiService.deleteAssignment(assignment.id)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						let newAssigment = quizzesAssignments.filter(
							el => el.id !== assignment.id,
						);
						this.setState({ quizzesAssignments: newAssigment });
					})
					.catch(error => this.deleteFail(error));
			})
			.catch(error => this.deleteFail(error));
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		spinnerId && this.props.removePageSpinner(spinnerId);
		this.setState({ isLoading: false, gamifyEntityTypeId: null });
	};

	render() {
		const { language, editMode, isBelongToUser, user } = this.props;
		const {
			updateTopic,
			courseId,
			sectionId,
			currentTopicId,
			showResourceOrActivity,
			topics,
			topicAttachedFiles,
			topicQuizzes,
			quizzesAssignments,
			translationService,
			topicIdForResource,
			zoomImagePath,
			zoomVideoPath,
			ongoingVideoId,
			zoomAudioPath,
			isLoading,
			selectedEntityData,
			gamifyData,
			gamifyEntityTypeId,
		} = this.state;

		const { showTopicForm } = this.props;

		return (
			translationService &&
			topics && (
				<div className="card-body">
					<GamificationItemsComponent
						entityData={selectedEntityData}
						gamifyData={gamifyData}
						entityTypeId={gamifyEntityTypeId}
						cancel={this.closeGamifyMoadl}
					/>
					{editMode ? (
						<button
							type="button"
							className="mindalay--btn-default mindalay--btn-small"
							onClick={() =>
								showTopicForm({
									isShow: true,
									courseId,
									sectionId,
									topics,
									updateTopic: updateTopic,
									submitted: this.topicSubmitted,
								})
							}>
							<i className="fas fa-plus mr-2" />
							{translationService.translate("TR_CREATE_TOPIC")}
						</button>
					) : null}
					{zoomImagePath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="close-svg">
								<CloseSvg />
							</div>
							<img
								src={zoomImagePath}
								alt="/"
								onClick={event => event.stopPropagation()}
							/>
						</div>
					) : null}
					{zoomVideoPath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<video
									controls={true}
									onClick={event => event.stopPropagation()}>
									<source src={zoomVideoPath} />
								</video>
							</div>
						</div>
					) : null}
					{zoomAudioPath ? (
						<div className="zoom-image-container" onClick={this.cancel}>
							<div className="zoom-image-block">
								<div className="close-svg">
									<CloseSvg />
								</div>
								<audio src={zoomAudioPath} controls />
							</div>
						</div>
					) : null}
					{/* {
        editMode && isShowTopicForm
          ? <TopicForm
            show={editMode && isShowTopicForm}
            courseId={courseId}
            sectionId={sectionId}
            topic={updateTopic}
            topics={topics}
            cancel={() => this.setState({ isShowTopicForm: false, updateTopic: null })}
            submitted={this.topicSubmitted}
          /> : null
      } */}
					{topics.map(topic => {
						return (
							<div
								key={topic.id}
								className={`card mindalay-card transparent-background`}>
								{editMode ? (
									<div className="dropleft setting-dropdown">
										<div
											className="setting-dropdown-btn"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false">
											<SettingsSvg />
										</div>
										<div className="dropdown-menu">
											{user && user.isGamifyAvailable ? (
												<Link
													to="#"
													className="dropdown-item"
													onClick={event => {
														event && event.stopPropagation();
														this.getGamifyDataByEntity(
															topic,
															GAMIFY_TOPIC_ENTITY_TYPE_ID,
															GAMIFY_SECTION_ENTITY_TYPE_ID,
														);
													}}>
													{isLoading ? (
														<span className=" d-flex justify-content-between align-items-center">
															{translationService.translate("TR_GAMIFY")}
															<div
																className="spinner-border text-success"
																style={{
																	width: "15px",
																	height: "15px",
																	borderWidth: "1px",
																}}
															/>
														</span>
													) : (
														<span className=" d-flex justify-content-between align-items-center">
															{translationService.translate("TR_GAMIFY")}
															<GameSvg fill="#28a745" width="20px" />
														</span>
													)}
												</Link>
											) : null}
											<Link
												to="#"
												className={`dropdown-item ${
													isLoading ? "pointer-events-none" : ""
												}`}
												onClick={() =>
													showTopicForm({
														isShow: true,
														courseId,
														sectionId,
														topics,
														updateTopic: topic,
													})
												}>
												{translationService.translate("TR_EDIT")}
											</Link>
											<Link
												to="#"
												className={`dropdown-item ${
													isLoading ? "pointer-events-none" : ""
												}`}
												onClick={() => this.deleteTopic(topic)}>
												{translationService.translate("TR_DELETE")}
											</Link>
										</div>
									</div>
								) : null}
								<div
									className={`card-header cursor-pointer ${
										this.props.topicClassName ? this.props.topicClassName : ""
									}`}
									aria-controls={`topic_${topic.id}`}
									aria-expanded={currentTopicId === topic.id}
									onClick={() => this.openTopicData(topic.id)}
									draggable={true}
									onDrop={() => this.drop(topic.id)}
									onDragOver={event => this.allowDrop(event)}
									onDragStart={() => this.drag(topic.id)}>
									<h5 className="mb-0 text-elipsis">
										<span>{/* {topicIndex + 1}. */}</span>•&nbsp;&nbsp;
										{topic.name}
									</h5>
									{user &&
									user.isOrganizationUser ? null : !this.props.history.location.pathname.includes(
											COURSES_KEY,
									  ) ? (
										topic.presentationFilePath || topic.hasContent ? (
											<span className="mindalay--some-process-status text-success"></span>
										) : (
											<small className="mindalay--some-process-status text-warning">
												{translationService.translate(
													"TR_NOT_COMPLETE_TOPIC_INFO",
												)}
											</small>
										)
									) : null}

									<span
										className="cursor-default"
										onClick={event => event.stopPropagation()}>
										{topic.description ? (
											<ShowMoreText
												lines={1}
												more={translationService.translate("TR_SHOW_MORE")}
												less={translationService.translate("TR_LESS")}
												className="content-css"
												anchorClass="my-anchor-css-class"
												expanded={false}
												truncatedEndingComponent={"... "}>
												<span className="topic-description-block">
													<ParserComponent text={topic.description} />
												</span>
											</ShowMoreText>
										) : null}
									</span>
								</div>
								{isBelongToUser ? (
									<Collapse in={currentTopicId === topic.id}>
										<div
											id={`topic_${topic.id}`}
											className="light-blue-background content-block">
											<div className="card-body">
												{editMode ? (
													<Auxiliary>
														<button
															type="button"
															className="mindalay--btn-default mindalay--btn-small mb-2"
															onClick={() =>
																this.toggleResourceOrActivity(true, topic)
															}>
															<i className="fas fa-plus mr-2" />
															{translationService.translate(
																"TR_ADD_RESOURCE_OR_ACTIVITY",
															)}
														</button>
														{showResourceOrActivity &&
														topic.id === topicIdForResource ? (
															<ResourceOrActivityModal
																closeModal={() =>
																	this.toggleResourceOrActivity(false)
																}
																added={() =>
																	this.addRecourseOrActivity(topic.id)
																}
															/>
														) : null}
													</Auxiliary>
												) : null}
												<div className="section-media-wrapper">
													<div className="row mt-3">
														{topic.imagePath ? (
															<div className="col-md-6 col-12">
																<div
																	className="topic-image"
																	style={{
																		backgroundImage: `url(${topic.imagePath})`,
																	}}></div>
															</div>
														) : null}
														{topic.presentationFilePath ? (
															<div className="col-md-6 col-12">
																{/* <video src={topic.presentationFilePath} controls></video> */}

																<video
																	className="cursor-pointer"
																	controls={true}
																	poster={PosterImage}
																	autoPlay={true}
																	onPointerDown={() =>
																		this.setState({ ongoingVideoId: topic.id })
																	}>
																	{ongoingVideoId === topic.id ? (
																		<source src={topic.presentationFilePath} />
																	) : null}
																</video>
															</div>
														) : null}
													</div>
												</div>
												{(topicAttachedFiles && topicAttachedFiles.length) ||
												(topicQuizzes && topicQuizzes.length) ? (
													<Auxiliary>
														<div className="section-content-wrapper">
															{topicAttachedFiles &&
															topicAttachedFiles.length ? (
																<Auxiliary>
																	<hr />
																	<div className="content-sub-title">
																		<h3>
																			{translationService.translate(
																				"TR_RESOURCES",
																			)}
																		</h3>
																	</div>
																</Auxiliary>
															) : null}
															{topicAttachedFiles.map(topicAttachedFile => {
																let fileType = "";
																if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(IMAGE_KEY)
																) {
																	fileType = IMAGE_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(VIDEO_KEY)
																) {
																	fileType = VIDEO_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	topicAttachedFile.mimeType.includes(AUDIO_KEY)
																) {
																	fileType = AUDIO_KEY;
																} else if (
																	topicAttachedFile.mimeType &&
																	(topicAttachedFile.mimeType.includes("pdf") || //pdf
																		topicAttachedFile.mimeType.includes(
																			"text",
																		) || //txt
																		topicAttachedFile.mimeType.includes(
																			"application",
																		))
																) {
																	fileType = FILE_KEY;
																}
																const mediaEditPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${topic.id}/${MEDIA_FORM_KEY}/${topicAttachedFile.topicFileId}?type=${fileType}`;
																return (
																	<div
																		key={topicAttachedFile.id}
																		className="section-content">
																		{editMode ? (
																			<div className="dropleft setting-dropdown">
																				<div
																					className="setting-dropdown-btn"
																					data-toggle="dropdown"
																					aria-haspopup="true"
																					aria-expanded="false">
																					<SettingsSvg />
																				</div>
																				<div className="dropdown-menu">
																					<Link
																						to={mediaEditPath}
																						className="dropdown-item">
																						{translationService.translate(
																							"TR_EDIT",
																						)}
																					</Link>
																					<Link
																						to="#"
																						className="dropdown-item"
																						onClick={() =>
																							this.removeAttachedFile(
																								topic.id,
																								topicAttachedFile,
																							)
																						}>
																						{translationService.translate(
																							"TR_REMOVE",
																						)}
																					</Link>
																				</div>
																			</div>
																		) : null}
																		{(() => {
																			switch (fileType) {
																				case IMAGE_KEY:
																					return (
																						<ImageSvg
																							onClick={() =>
																								this.zoomImage(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case VIDEO_KEY:
																					return (
																						<VideoSvg
																							onClick={() =>
																								this.zoomVideo(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case AUDIO_KEY:
																					return (
																						<AudioSvg
																							onClick={() =>
																								this.zoomAudio(
																									topicAttachedFile.filePath,
																								)
																							}
																						/>
																					);
																				case FILE_KEY:
																					return <FileSvg />;
																				default:
																					break;
																			}
																		})()}
																		{topicAttachedFile.mimeType.includes(
																			IMAGE_KEY,
																		) ? (
																			<p
																				className="text-elipsis cursor-pointer d-block pr--4"
																				onClick={() =>
																					this.zoomImage(
																						topicAttachedFile.filePath,
																					)
																				}
																				title={
																					topicAttachedFile?.nameAsResource
																				}>
																				{topicAttachedFile.nameAsResource}
																			</p>
																		) : null}
																		{topicAttachedFile.mimeType.includes(
																			VIDEO_KEY,
																		) ? (
																			<p
																				className="text-elipsis cursor-pointer d-block pr--4"
																				onClick={() =>
																					this.zoomVideo(
																						topicAttachedFile.filePath,
																					)
																				}
																				title={
																					topicAttachedFile?.nameAsResource
																				}>
																				{topicAttachedFile.nameAsResource}
																			</p>
																		) : null}
																		{topicAttachedFile.mimeType.includes(
																			AUDIO_KEY,
																		) ? (
																			<p
																				className="text-elipsis cursor-pointer d-block pr--4"
																				onClick={() =>
																					this.zoomAudio(
																						topicAttachedFile.filePath,
																					)
																				}
																				title={
																					topicAttachedFile?.nameAsResource
																				}>
																				{topicAttachedFile.nameAsResource}
																			</p>
																		) : null}
																		{topicAttachedFile.mimeType.includes(
																			"pdf",
																		) || //pdf
																		topicAttachedFile.mimeType.includes(
																			"text",
																		) || //txt
																		topicAttachedFile.mimeType.includes(
																			"application",
																		) ? (
																			<a
																				target="blank"
																				href={topicAttachedFile.filePath}
																				className="file-item text-secondary">
																				{topicAttachedFile.nameAsResource}
																			</a>
																		) : null}
																	</div>
																);
															})}
															{topicQuizzes.length ? (
																<Auxiliary>
																	<hr />
																	<div className="content-sub-title">
																		<h3>
																			{translationService.translate(
																				"TR_QUIZZES",
																			)}
																		</h3>
																	</div>
																	{topicQuizzes.map(topicQuiz => {
																		const quizAttemptViewPath = `/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${VIEW_KEY}/${courseId}/${topicQuiz.id}`;
																		const editPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${topicQuiz.id}/${QUIZ_FORM_KEY}/${topicQuiz.id}`;
																		const attachQuestionsPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_VIEW_KEY}/${topicQuiz.id}`;
																		return (
																			<div
																				key={topicQuiz.id}
																				className="section-content">
																				{editMode ? (
																					<div className="dropleft setting-dropdown">
																						<div
																							className="setting-dropdown-btn"
																							data-toggle="dropdown"
																							aria-haspopup="true"
																							aria-expanded="false">
																							<SettingsSvg />
																						</div>
																						<div className="dropdown-menu">
																							{user &&
																							user.isGamifyAvailable ? (
																								<Link
																									to="#"
																									className="dropdown-item"
																									onClick={event => {
																										event &&
																											event.stopPropagation();
																										this.getGamifyDataByEntity(
																											topicQuiz,
																											GAMIFY_QUIZ_ENTITY_TYPE_ID,
																											GAMIFY_TOPIC_ENTITY_TYPE_ID,
																											topic.id,
																										);
																									}}>
																									{isLoading ? (
																										<span className=" d-flex justify-content-between align-items-center">
																											{translationService.translate(
																												"TR_GAMIFY",
																											)}
																											<div
																												className="spinner-border text-success"
																												style={{
																													width: "15px",
																													height: "15px",
																													borderWidth: "1px",
																												}}
																											/>
																										</span>
																									) : (
																										<span className=" d-flex justify-content-between align-items-center">
																											{translationService.translate(
																												"TR_GAMIFY",
																											)}
																											<GameSvg
																												fill="#28a745"
																												width="20px"
																											/>
																										</span>
																									)}
																								</Link>
																							) : null}
																							<Link
																								to={editPath}
																								className="dropdown-item">
																								{translationService.translate(
																									"TR_EDIT",
																								)}
																							</Link>
																							<Link
																								to={attachQuestionsPath}
																								className="dropdown-item">
																								{translationService.translate(
																									"TR_ATTACH_QUESTIONS",
																								)}
																							</Link>
																							<Link
																								to="#"
																								className="dropdown-item"
																								onClick={() =>
																									this.deleteQuiz(topicQuiz)
																								}>
																								{translationService.translate(
																									"TR_DELETE",
																								)}
																							</Link>
																						</div>
																					</div>
																				) : null}
																				<QuizSvg />
																				<Link
																					to={quizAttemptViewPath}
																					className="text-elipsis full-width mr-4"
																					title={topicQuiz.name}
																					onClick={() =>
																						this.props.setPureLayoutBackUrl(
																							this.props.location.pathname,
																						)
																					}>
																					<p>{topicQuiz.name}</p>
																				</Link>
																			</div>
																		);
																	})}
																</Auxiliary>
															) : null}
														</div>
													</Auxiliary>
												) : null}
												{quizzesAssignments.length ? (
													<Auxiliary>
														<hr />
														<div className="content-sub-title">
															<h3>
																{/* {translationService.translate(
																				"TR_QUIZZES",
																			)} */}
																{translationService.translate("TR_ASSIGNMENTS")}
															</h3>
														</div>
														{quizzesAssignments.map(item => {
															const editPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${topic.id}/assignment-form/${item.id}`;
															const getFilPath = () => {
																if (item.files && item.files.length) {
																	const imgpath = item.files.find(el =>
																		el.mimeType.includes("image"),
																	);
																	if (imgpath) {
																		return (
																			<img
																				src={imgpath.filePath}
																				className="mr-4"
																				style={{
																					objectFit: "contain",
																					width: 22,
																				}}
																			/>
																		);
																	} else return null;
																} else {
																	return null;
																}
															};
															return (
																<div
																	key={item.id}
																	className="section-content-wrapper">
																	<div className="section-content">
																		{editMode ? (
																			<div className="dropleft setting-dropdown">
																				<div
																					className="setting-dropdown-btn"
																					data-toggle="dropdown"
																					aria-haspopup="true"
																					aria-expanded="false">
																					<SettingsSvg />
																				</div>
																				<div className="dropdown-menu">
																					{user && user.isGamifyAvailable ? (
																						<Link
																							to="#"
																							className="dropdown-item"
																							onClick={event => {
																								event &&
																									event.stopPropagation();
																								this.getGamifyDataByEntity(
																									item,
																									GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID,
																									GAMIFY_TOPIC_ENTITY_TYPE_ID,
																									topic.id,
																								);
																							}}>
																							{isLoading ? (
																								<span className=" d-flex justify-content-between align-items-center">
																									{translationService.translate(
																										"TR_GAMIFY",
																									)}
																									<div
																										className="spinner-border text-success"
																										style={{
																											width: "15px",
																											height: "15px",
																											borderWidth: "1px",
																										}}
																									/>
																								</span>
																							) : (
																								<span className=" d-flex justify-content-between align-items-center">
																									{translationService.translate(
																										"TR_GAMIFY",
																									)}
																									<GameSvg
																										fill="#28a745"
																										width="20px"
																									/>
																								</span>
																							)}
																						</Link>
																					) : null}
																					<Link
																						to={editPath}
																						className="dropdown-item">
																						{translationService.translate(
																							"TR_EDIT",
																						)}
																					</Link>
																					<Link
																						to="#"
																						className="dropdown-item"
																						onClick={() =>
																							this.deleteAssigment(item)
																						}>
																						{translationService.translate(
																							"TR_DELETE",
																						)}
																					</Link>
																				</div>
																			</div>
																		) : null}
																		{/* {getFilPath()} */}
																		{/* <QuizSvg /> */}
																		<p>{item.title}</p>
																	</div>
																</div>
															);
														})}
													</Auxiliary>
												) : null}
											</div>
										</div>
									</Collapse>
								) : null}
							</div>
						);
					})}
				</div>
			)
		);
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	language: state.language.language,
	translations: state.translation.translations,
	curiculumBreadCrumb: state.main.curiculumBreadCrumb,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	setCurrentCourse,
	setPureLayoutBackUrl,
	setCuriculumBreadCrumb,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(Topics);
